<template>
<div>
    <b-row>

        <b-col md="6">
            <CCard>
                <CCardHeader color="dark" text-color="white">
                    <i class="fas fa-chart-bar fa-md"></i> <span class="h5"> Estadísticas</span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">ESTADÍSTICAS DEL SISTEMA PARA PERFILES
                    </span>
                    <hr>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="6" class=" my-2 icon-custom vr">
                            <i class="fas fa-users-cog fa-3x ico"> </i>
                            <br>
                            <span class="lg-numero">{{rlistaPerfiles.length}}</span>
                            <br>
                            <span class="text-muted h6">Perfiles</span>
                        </b-col>
                        <b-col cols="6" class=" my-2 icon-custom ">
                            <i class="fas fa-cogs fa-3x ico"> </i>
                            <br>
                            <span class="lg-numero">{{count}}</span>
                            <br>
                            <span class="text-muted h6">Modulos</span>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="dark" text-color="white">
                    <i class="fas fa-cog"></i><span class="h5"> Gestión de perfiles </span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="text-center justify-content-center">
                        <b-col md="6" class="my-2 ">
                            <b-button block size="md" variant="custom" @click="abrirModalRegistrarPermisos()">
                                <i class="fas fa-plus-circle fa-3x pb-1" style="inline-size: auto"></i>
                                <br>Nuevo perfil
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>

        <b-col md="12">
            <CCard>
                <CCardHeader color="dark" text-color="white">
                    <i class="fas fa-list fa-md"></i> <span class="h5"> Perfiles registrados</span>
                </CCardHeader>

                <CCardBody>
                    <div>
                        <b-row>
                            <b-col lg="2">
                                <b-form-group label="Registros por página" class="text-muted">
                                    <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col lg="7">
                            </b-col>
                            <b-col lg="3">
                                <b-form-group label="Busqueda:" class="text-muted">
                                    <b-input-group size="xl">
                                        <b-input-group-prepend is-text>
                                            <i class="fas fa-search  my-0 mx-0"></i>
                                        </b-input-group-prepend>
                                        <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col md="12">
                                <b-table hover bordered show-empty mediun responsive outlined :items="rlistaPerfiles" :fields="campoPerfiles" :current-page="currentPage" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                    <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                    <template v-slot:cell(opciones)="param">
                                        <b-button size="sm" v-c-tooltip.hover.click="'Ver detalles'" @click="abrirModalActualizarPermisos(param)" variant="dark" class=" mr-1 mb-1">
                                            <span class="btn-inner--icon">
                                                <i class="fas fa-search my-0 mx-0"></i>
                                            </span>
                                        </b-button>

                                        <b-button v-if="param.item.estado == 2" size="sm" class="mr-1 mb-1" v-c-tooltip.hover.click="'Eliminar'" @click="eliminarPerfil(param, 1)" variant="danger">
                                            <span class="btn-inner--icon">
                                                <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                            </span>
                                        </b-button>
                                        <b-button v-if="param.item.estado == 1" size="sm" class="mr-1 mb-1" v-c-tooltip.hover.click="'Restaurar'" @click="eliminarPerfil(param, 2)" variant="success">
                                            <span class="btn-inner--icon">
                                                <i class="fas fa-history  my-0 mx-0"></i>
                                            </span>
                                        </b-button>
                                    </template>
                                </b-table>
                            </b-col>
                        </b-row>

                    </div>
                </CCardBody>
                <CCardFooter>
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="porPagina" align="right" size="sm" class="my-0"></b-pagination>
                </CCardFooter>
            </CCard>
        </b-col>
    </b-row>
    <CModal :closeOnBackdrop="false" color="dark" size="xl" :show.sync="modalRegistrarPerfil">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Nuevo perfil</span>
            </h6>
            <CButtonClose @click="modalRegistrarPerfil = false" class="text-white" />
        </template>

        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(registrarPerfil)">
                <b-row>
                    <b-col md="12">
                        <b-tabs content-class="mt-3" active-nav-item-class="font-weight-bold text-uppercase text-dark">
                            <b-tab title="Datos" active>
                                <b-row>
                                    <b-col md="5">
                                        <validation-provider name="nombre" :rules="{required: true}" v-slot="validationContext">
                                            <b-form-group label="Nombre:" class="mb-2">
                                                <b-form-input size="md" :state="getValidationState(validationContext)" placeholder="Ingrese el nombre" :value="datosPerfil.nombre" @change="datosPerfil.nombre = $event"></b-form-input>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>
                                    <b-col>
                                        <validation-provider name="cliente" rules="required" v-slot="{errors}">
                                            <b-form-group label="Cliente:" class="mb-2">
                                                <v-select :reduce="listaClientes =>listaClientes.idCliente" label="razonSocial" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosPerfil.idCliente , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosPerfil.idCliente" :options="listaClientes" @search:blur="blurCliente">
                                                    <span slot="no-options" class="text-muted">No hay datos que mostrar.<router-link :to="{name: 'Clientes master'}"> Registra aquí</router-link></span>
                                                </v-select>
                                                <div class="invalid-feedback">{{ errors[0]}}</div>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>
                                </b-row>
                            </b-tab>
                            <b-tab title="Permisos">
                                <b-row>
                                    <b-col md="12" class="my-3">
                                        <span class="h6 text-muted">Si la opción Ver de una sección no está marcada, tampoco podrás ver sus hijos</span>
                                    </b-col>
                                    <b-col md="12" class="mb-1">
                                        <b-dropdown :disabled="disabled" split @click="seleccionarTodos()" size="sm" variant="dark" class="m-2 show-arrow-down-dropdown-button">
                                            <template #button-content>
                                                <i class="fas fa-check-square"></i> Seleccionar todo
                                            </template>
                                            <b-dropdown-item size="sm" @click="seleccionarChbx('r')">Solo ver</b-dropdown-item>
                                            <b-dropdown-item @click="seleccionarChbx('c')">Solo crear</b-dropdown-item>
                                            <b-dropdown-item @click="seleccionarChbx('u')">Solo modificar</b-dropdown-item>
                                            <b-dropdown-item @click="seleccionarChbx('d')">Solo eliminar</b-dropdown-item>
                                        </b-dropdown>
                                        <b-button :disabled="disabled" size="sm" variant="dark" class=" mr-2" @click="limpiarTodos()">
                                            <i class="far fa-square"></i> Limpiar todo
                                        </b-button>
                                    </b-col>
                                    <b-col md="6" class="mt-3" v-for="column in columns" :key="column.index">
                                        <ul>
                                            <div v-for="permiso in column" :key="permiso.idModulo">
                                                <div v-if="permiso.visible == 1">
                                                    <template v-if="permiso.tipo == 1 ">
                                                        <li>
                                                            <hr class="my-2">
                                                            <div class="vr">
                                                                <span class="h6">{{permiso.nombre}}</span>
                                                                <b-form-group class="my-1">
                                                                    <b-form-checkbox-group plain :options="permiso.options" v-model="permiso.data"></b-form-checkbox-group>
                                                                </b-form-group>
                                                            </div>
                                                        </li>
                                                    </template>
                                                    <template v-if="permiso.tipo == 2">
                                                        <ul>
                                                            <li>
                                                                <div class="vr">
                                                                    <span class="h6">{{permiso.nombre}} </span>
                                                                    <b-form-group class="my-1">
                                                                        <b-form-checkbox-group plain :options="permiso.options" v-model="permiso.data"></b-form-checkbox-group>
                                                                    </b-form-group>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </template>
                                                    <template v-if="permiso.tipo == 3">
                                                        <ul>
                                                            <ul>
                                                                <li>
                                                                    <div class="vr">
                                                                        <span class="h6">{{permiso.nombre}}</span>
                                                                        <b-form-group class="my-1">
                                                                            <b-form-checkbox-group plain :options="permiso.options" v-model="permiso.data"></b-form-checkbox-group>
                                                                        </b-form-group>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </ul>
                                                    </template>
                                                </div>

                                            </div>
                                        </ul>
                                    </b-col>
                                </b-row>
                            </b-tab>
                        </b-tabs>

                    </b-col>
                    <b-col class="my-2 text-right" md="12">
                        <b-button :disabled="disabled" size="md" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button size="md" variant="danger" @click="modalRegistrarPerfil = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <template #footer>
            <span></span>
        </template>
    </CModal>

    <CModal :closeOnBackdrop="false" color="dark" size="xl" :show.sync="modalDetallePerfil">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Actualizar perfil</span>
            </h6>
            <CButtonClose @click="modalDetallePerfil = false" class="text-white" />
        </template>

        <validation-observer ref="observer2" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(registrarPerfil)">
                <b-row>
                    <b-col md="12">
                        <b-tabs content-class="mt-3" active-nav-item-class="font-weight-bold text-uppercase text-dark">
                            <b-tab title="Datos" active>
                                <b-row>
                                    <b-col md="5">
                                        <validation-provider name="nombre" :rules="{required: true}" v-slot="validationContext">
                                            <b-form-group label="Nombre:" class="mb-2">
                                                <b-form-input size="md" :state="getValidationState(validationContext)" placeholder="Ingrese el nombre" :value="datosPerfil.nombre" @change="datosPerfil.nombre = $event"></b-form-input>
                                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>
                                    <b-col>
                                        <validation-provider name="cliente" rules="required" v-slot="{errors}">
                                            <b-form-group label="Cliente:" class="mb-2">
                                                <v-select :reduce="listaClientes =>listaClientes.idCliente" label="razonSocial" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosPerfil.idCliente , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosPerfil.idCliente" :options="listaClientes" @search:blur="blurActualizarCliente">
                                                    <span slot="no-options" class="text-muted">No hay datos que mostrar.<router-link :to="{name: 'Clientes master'}"> Registra aquí</router-link></span>
                                                </v-select>
                                                <div class="invalid-feedback">{{ errors[0]}}</div>
                                            </b-form-group>
                                        </validation-provider>
                                    </b-col>
                                </b-row>
                            </b-tab>
                            <b-tab title="Permisos">
                                <b-row>
                                    <b-col md="12" class="my-3">
                                        <span class="h6 text-muted">Si la opción Ver de una sección no está marcada, tampoco podrás ver sus hijos</span>
                                    </b-col>
                                    <b-col md="12" class="mb-1">
                                        <b-dropdown :disabled="disabled" split @click="seleccionarTodos()" size="sm" variant="dark" class="m-2 show-arrow-down-dropdown-button">
                                            <template #button-content>
                                                <i class="fas fa-check-square"></i> Seleccionar todo
                                            </template>
                                            <b-dropdown-item size="sm" @click="seleccionarChbx('r')">Solo ver</b-dropdown-item>
                                            <b-dropdown-item @click="seleccionarChbx('c')">Solo crear</b-dropdown-item>
                                            <b-dropdown-item @click="seleccionarChbx('u')">Solo modificar</b-dropdown-item>
                                            <b-dropdown-item @click="seleccionarChbx('d')">Solo eliminar</b-dropdown-item>
                                        </b-dropdown>
                                        <b-button :disabled="disabled" size="sm" variant="dark" class=" mr-2" @click="limpiarTodos()">
                                            <i class="far fa-square"></i> Limpiar todo
                                        </b-button>
                                    </b-col>
                                    <b-col md="6" class="mt-3" v-for="column in columns" :key="column.index">
                                        <ul>
                                            <div v-for="permiso in column" :key="permiso.idModulo">
                                                <div v-if="permiso.visible == 1">
                                                    <template v-if="permiso.tipo == 1 ">
                                                        <li>
                                                            <hr class="my-2">
                                                            <div class="vr">
                                                                <span class="h6">{{permiso.nombre}}</span>
                                                                <b-form-group class="my-1">
                                                                    <b-form-checkbox-group plain :options="permiso.options" v-model="permiso.data"></b-form-checkbox-group>
                                                                </b-form-group>
                                                            </div>
                                                        </li>
                                                    </template>
                                                    <template v-if="permiso.tipo == 2">
                                                        <ul>
                                                            <li>
                                                                <div class="vr">
                                                                    <span class="h6">{{permiso.nombre}} </span>
                                                                    <b-form-group class="my-1">
                                                                        <b-form-checkbox-group plain :options="permiso.options" v-model="permiso.data"></b-form-checkbox-group>
                                                                    </b-form-group>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </template>
                                                    <template v-if="permiso.tipo == 3">
                                                        <ul>
                                                            <ul>
                                                                <li>
                                                                    <div class="vr">
                                                                        <span class="h6">{{permiso.nombre}}</span>
                                                                        <b-form-group class="my-1">
                                                                            <b-form-checkbox-group plain :options="permiso.options" v-model="permiso.data"></b-form-checkbox-group>
                                                                        </b-form-group>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </ul>
                                                    </template>
                                                </div>

                                            </div>
                                        </ul>
                                    </b-col>
                                </b-row>
                            </b-tab>
                        </b-tabs>

                    </b-col>
                    <b-col class="my-2 text-right" md="12">
                        <b-button :disabled="disabled" size="md" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button :disabled="disabled" size="md" variant="danger" @click="modalDetallePerfil = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <template #footer>
            <span></span>
        </template>
    </CModal>
</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {
    data() {
        return {

            datosPerfil: {
                idTipoPerfil: null,
                nombre: '',
                idCliente: '',
                permisos: []
            },

            disabled: false,

            modalDetallePerfil: false,
            modalRegistrarPerfil: false,

            totalRows: 0,
            currentPage: 1,
            filter: null,
            filterOn: [],
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],
            campoPerfiles: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "cliente",
                    label: "Cliente",
                    class: "text-center",
                }, {
                    key: "nombre",
                    label: "Nombre",
                    class: "text-center",
                },
                {
                    key: "count",
                    label: "Permisos",
                    class: "text-center",
                }, {
                    key: "opciones",
                    label: "",
                    class: "text-center",
                }
            ],
            rlistaPerfiles: [],
            count: 0,
            listaClientes: [],

        }
    },
    methods: {

        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        blurActualizarCliente() {
            this.computedActualizarForm.refs.cliente.validate();
        },
        blurCliente() {
            this.computedForm.refs.cliente.validate();
        },
        seleccionarChbx(x) {
            for (let j in this.datosPerfil.permisos) {
                let datax = [];
                let c, r, u, d;
                //debe seleccionar si existe el crud
                if (this.datosPerfil.permisos[j].model.includes('r') && x == 'r') {
                    r = 'r'
                    datax.push(r);
                };
                if (this.datosPerfil.permisos[j].model.includes('c') && x == 'c') {
                    c = 'c'
                    datax.push(c);
                };
                if (this.datosPerfil.permisos[j].model.includes('u') && x == 'u') {
                    u = 'u'
                    datax.push(u);
                };
                if (this.datosPerfil.permisos[j].model.includes('d') && x == 'd') {
                    d = 'd'
                    datax.push(d);
                };
                this.datosPerfil.permisos[j].data = datax;
            }
        },
        seleccionarTodos() {
            for (let j in this.datosPerfil.permisos) {
                let datax = [];
                let c, r, u, d;
                //debe seleccionar si existe el crud
                if (this.datosPerfil.permisos[j].model.includes('r')) {
                    r = 'r'
                    datax.push(r);
                };
                if (this.datosPerfil.permisos[j].model.includes('c')) {
                    c = 'c'
                    datax.push(c);
                };
                if (this.datosPerfil.permisos[j].model.includes('u')) {
                    u = 'u'
                    datax.push(u);
                };
                if (this.datosPerfil.permisos[j].model.includes('d')) {
                    d = 'd'
                    datax.push(d);
                };

                this.datosPerfil.permisos[j].data = datax;
            }
        },
        limpiarTodos() {
            for (let j in this.datosPerfil.permisos) {
                this.datosPerfil.permisos[j].data = [];
            }
        },

        listarPerfiles() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "master/listar-perfiles")
                .then(function (response) {
                    me.rlistaPerfiles = response.data;
                    me.totalRows = me.rlistaPerfiles.length;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        listarCantidadModulos() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "master/contar-modulos")
                .then(function (response) {
                    me.count = response.data[0].count;

                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        registrarPerfil() {
            let me = this;
            me.disabled = true;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "master/registrar-perfil",
                    me.datosPerfil, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje);
                    me.modalDetallePerfil = false;
                    me.modalRegistrarPerfil = false;
                    me.listarPerfiles()
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;

                });
        },

        eliminarPerfil(param, estado) {
            let me = this
            let texto = estado == 1 ? 'eliminar' : 'restaurar';

            me.$swal.fire({
                title: '¿Estas seguro de ' + texto + ' el perfil?',
                text: "¡No podrás revertir esto!",
                icon: estado == 1 ? 'warning' : 'success',
                showCancelButton: false,
                confirmButtonColor: estado == 1 ? '#d33' : '#2EB85C',
                confirmButtonText: estado == 1 ? 'Eliminar' : 'Restaurar',
            }).then((result) => {
                if (result.isConfirmed) {
                    axios
                        .post(CONSTANTES.URL_RUTA_SERVICIOS + "master/eliminar-perfil", {
                            idTipoPerfil: param.item.idTipoPerfil,
                            estado: estado
                        }, {
                            headers: {
                                Authorization: `Bearer ${localStorage.token}`,
                            }
                        })
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)
                            me.listarPerfiles();
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },

        /*-----------Modulso- ---*/
        abrirModalRegistrarPermisos(param) {

            let me = this;
            me.datosPerfil.permisos = [];
            me.datosPerfil.idCliente = null
            me.datosPerfil.nombre = "";

            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "master/listar-permisos-perfil")
                .then(function (response) {
                    // let listaPermisos = r;
                    for (let k in response.data) {
                        let visiblex = 1;
                        let optionsx = [];
                        let c, r, u, d;

                        if (response.data[k].valorD.includes('r')) {
                            r = {
                                text: 'Ver',
                                value: 'r'
                            }
                            optionsx.push(r);
                        };
                        if (response.data[k].valorD.includes('c')) {
                            c = {
                                text: 'Crear',
                                value: 'c'
                            }
                            optionsx.push(c);
                        };
                        if (response.data[k].valorD.includes('u')) {
                            u = {
                                text: 'Modificar',
                                value: 'u'
                            }
                            optionsx.push(u);
                        };
                        if (response.data[k].valorD.includes('d')) {
                            d = {
                                text: 'Eliminar',
                                value: 'd'
                            }
                            optionsx.push(d);
                        };

                        me.datosPerfil.permisos.push({
                            idModulo: response.data[k].idModulo,
                            tipo: response.data[k].tipo,
                            nombre: response.data[k].descripcion,
                            model: response.data[k].valorD,
                            options: optionsx,
                            visible: visiblex,
                            data: [],
                        })
                    }
                    me.modalRegistrarPerfil = true;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!');

                });
        },

        abrirModalActualizarPermisos(param) {
            let me = this;
            me.datosPerfil.permisos = [];
            me.datosPerfil.idCliente = param.item.idCliente;
            me.datosPerfil.nombre = param.item.nombre;
            me.datosPerfil.idTipoPerfil = param.item.idTipoPerfil;

            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "master/obtener-permisos-perfil", {
                    params: {
                        idTipoPerfil: param.item.idTipoPerfil,
                    }
                })
                .then(function (response) {
                    for (let k in response.data) {
                        let optionsx = [];
                        let c, r, u, d;
                        let visiblex = 1;

                        if (response.data[k].valorD.includes('r')) {
                            r = {
                                text: 'Ver',
                                value: 'r'
                            }
                            optionsx.push(r);
                        };
                        if (response.data[k].valorD.includes('c')) {
                            c = {
                                text: 'Crear',
                                value: 'c'
                            }
                            optionsx.push(c);
                        };
                        if (response.data[k].valorD.includes('u')) {
                            u = {
                                text: 'Modificar',
                                value: 'u'
                            }
                            optionsx.push(u);
                        };
                        if (response.data[k].valorD.includes('d')) {
                            d = {
                                text: 'Eliminar',
                                value: 'd'
                            }
                            optionsx.push(d);
                        };

                        me.datosPerfil.permisos.push({
                            idModulo: response.data[k].idModulo,
                            idPermisoPerfil: response.data[k].idPermisoPerfil,
                            tipo: response.data[k].tipo,
                            model: response.data[k].valorD,
                            nombre: response.data[k].descripcion,
                            options: optionsx,
                            data: [
                                response.data[k].c == 1 ? 'c' : '',
                                response.data[k].r == 1 ? 'r' : '',
                                response.data[k].u == 1 ? 'u' : '',
                                response.data[k].d == 1 ? 'd' : '',
                            ],
                            visible: visiblex
                        })
                    }
                    me.modalDetallePerfil = true;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!');

                });
        },

        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        },
        listarCliente() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "master/listar-clientes-ventas")
                .then(function (response) {
                    me.listaClientes = response.data;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        resetModalRegistrarPerfil() {
            this.$nextTick(() => {
                this.$refs.observer.reset();
            });
            this.datosPerfil.nombre = '';
            this.datosPerfil.idCliente = null;
            this.datosPerfil.permisos = [];
            this.datosPerfil.idTipoPerfil = null;

        },
        resetModalDetallePerfil() {
            this.$nextTick(() => {
                this.$refs.observer2.reset();
            });
            this.datosPerfil.nombre = '';
            this.datosPerfil.idCliente = null;
            this.datosPerfil.permisos = [];
            this.datosPerfil.idTipoPerfil = null;

        }
    },
    computed: {
        columns() {
            let listaPermisos = this.datosPerfil.permisos.filter(x => x.visible == 1);
            if (listaPermisos.length > 0) {
                let columns = []
                let mid = Math.ceil(listaPermisos.length / 2)
                for (let col = 0; col < 2; col++) {
                    columns.push(listaPermisos.slice(col * mid, col * mid + mid))
                }
                return columns
            }
        },
        computedForm() {
            return this.$refs.observer;
        },
        computedActualizarForm() {
            return this.$refs.observer2;
        }
    },
    watch: {
        modalRegistrarPerfil: function (val) {
            if (val == false) {
                this.resetModalRegistrarPerfil();
            }
        },
        modalDetallePerfil: function (val) {
            if (val == false) {
                this.resetModalDetallePerfil();
            }
        }
    },
    mounted() {
        let me = this;
        me.listarPerfiles();
        me.listarCantidadModulos();
        me.listarCliente();
    }

}
</script>
